.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Fade in animation */
.fadeIn {
  animation: fadeIn ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: backwards;
}

@keyframes fadeIn {
  0% {
    padding-bottom: 10%;
    opacity: 0;
  }
  100% {
    padding-bottom: 0%;
    opacity: 1;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;
